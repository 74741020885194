import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Layout from '../../components/Layout'
import LeftWithImage from '../../components/Global/Hero/LeftWithImage'
import { YellowButton } from '../../components/Global/Button'
import SEO from '../../components/Global/SEO'
import { OffsetGridWrapper } from '../../components/Global/Content/OffsetGrid'
import { RoyalPortrush, RoyalCountyDown, Lahinch, KClub, MountJuliet, DruidsGlen } from '../../components/Services/GolfCourses'
import { Centered2x2GridWrapper, GridWrapper, GridItem } from '../../components/Global/Content/Centered2x2Grid'
import SectionTitle from '../../components/Global/SectionTitle'
import { EmojiHappy, User, Globe, ClipboardCheck } from '../../components/Global/Icons'

const GolfTripsPage = ({ location }) => {
  const { seoImg, golf } = useStaticQuery(graphql`
    query {
      seoImg: file(relativePath: { eq: "royal-portrush-golf-trip.jpeg" }) {
        childImageSharp {
          fixed(quality: 90, width: 1200, height: 630) {
            src
          }
        }
      }
      golf: file(relativePath: { eq: "royal-portrush-golf-trip.jpeg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 900, maxHeight: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <Layout>

      <SEO
        title="Chauffeur Driven Golf Trips | Royal Portrush, The K Club, Royal County Down, Druids Glen"
        description="Take to the fairways on some of Ireland’s most renowned courses in the luxury of our chauffeur driven vehicles, with the local knowledge of our professional drivers at your disposal."
        image={seoImg.childImageSharp.fixed.src}
        pathname={location.pathname}
      />

      <LeftWithImage
        image={golf.childImageSharp.fluid}
        imageAlt="Chauffeur Driven Golf Trips"
      >
        <div className="text-sm font-semibold uppercase tracking-wide text-gray-500 sm:text-base lg:text-sm xl:text-base">
          Services
        </div>
        <h2 className="mt-1 text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:leading-none sm:text-6xl lg:text-5xl xl:text-6xl">
          Chauffeur Driven
          <br className="hidden md:inline" />
          <span className="text-yellow-300">Golf Trips</span>
        </h2>
        <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
          Take to the fairways on some of Ireland’s most renowned courses in the luxury of our chauffeur driven vehicles, with the local knowledge of our professional drivers at your disposal. 
        </p>
        <YellowButton to="/book-now" className="inline-block mt-8">Book Now</YellowButton>
      </LeftWithImage>

      <Centered2x2GridWrapper>
        <SectionTitle
          title="Experience the best golf travel"
          text="Sample the best golf Ireland has to offer as we transport you in our luxury vehicles. We want your sole concern to be your game."
        />
        <GridWrapper>
          <GridItem
            title="Meet and Greet"
            description="We will meet and greet you at the airport and take you to and from the golf course and hotel each day in our luxury chauffeured transportation."
            icon={<EmojiHappy />}
          />
          <GridItem
            title="Knowledgeable Drivers"
            description="Our locally experienced staff have a wealth of knowledge on all Championship Links Golf Courses, tourist attractions and history of the country."
            icon={<User />}
          />
          <GridItem
            title="World Famous Courses"
            description="At Chauffeur Me we offer a huge selection of the most famous courses in Ireland, helping to build bespoke tours, including visits to the best attractions the area has to offer."
            icon={<Globe />}
          />
          <GridItem
            title="Relax &amp; Enjoy"
            description="As soon as you arrive we want you to relax and enjoy every moment knowing all the organising and stresses of your golf trip in Ireland are taken care of."
            icon={<ClipboardCheck />}
          />
        </GridWrapper>
      </Centered2x2GridWrapper>

      <OffsetGridWrapper title="Play Ireland's Best Golf Courses">
        <RoyalPortrush />
        <RoyalCountyDown />
        <Lahinch />
        <KClub />
        <MountJuliet />
        <DruidsGlen />
      </OffsetGridWrapper>

    </Layout>
  )
}

export default GolfTripsPage