import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GridItemWithImage as GridItem } from '../../Global/Content/OffsetGrid'

const RoyalPortrush = () => {
  const { image } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "royal-portrush-golf-trip.jpeg" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 630, maxHeight: 420) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <GridItem
      title="Royal Portrush Golf Club"
      description="An abode for crosswinds and sudden showers, the Dunluce Links was designed by Harry Colt and it sees rapid changes in weather while offering one of the best views of Ireland overlooking the limestone cliffs and white rocks."
      sources={image.childImageSharp.fluid}
      imageAlt="Golf trips to Royal Portrush with Chauffeur Me."
    />
  )
}

export default RoyalPortrush