import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GridItemWithImage as GridItem } from '../../Global/Content/OffsetGrid'

const MountJuliet = () => {
  const { image } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "mount-juliet-golf-club.jpg" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 630, maxHeight: 420) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <GridItem
      title="Mount Juliet Golf Club"
      description="Designed by Jack Nicklaus, Mount Juliet golf course first opened in 1991. Since then the course has established itself as a premier venue for top professional and leisure golfers from around the world."
      sources={image.childImageSharp.fluid}
      imageAlt="Golf trips to Mount Juliet Golf Club with Chauffeur Me."
    />
  )
}

export default MountJuliet