import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GridItemWithImage as GridItem } from '../../Global/Content/OffsetGrid'

const KClub = () => {
  const { image } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "k-club-golf.jpg" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 630, maxHeight: 420) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <GridItem
      title="The K Club"
      description="Host to the Ryder Cup in 2006, two courses have lush green lawns and it resembles a typical American parkland course. A round of golf at the world-famous K Club is a must for anyone who loves the sport."
      sources={image.childImageSharp.fluid}
      imageAlt="Golf trips to The K Club with Chauffeur Me."
    />
  )
}

export default KClub