import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GridItemWithImage as GridItem } from '../../Global/Content/OffsetGrid'

const RoyalCountyDown = () => {
  const { image } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "royal-county-down-golf-club.jpg" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 630, maxHeight: 420) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <GridItem
      title="Royal County Down Golf Club"
      description="The favourite of Tiger Woods, it is frequented by Darren Clarke and Rory Mcllroy and was founded in 1889 along the Irish Sea with each hole being distinct in this course."
      sources={image.childImageSharp.fluid}
      imageAlt="Golf trips to Royal County Down with Chauffeur Me."
    />
  )
}

export default RoyalCountyDown