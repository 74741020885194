import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GridItemWithImage as GridItem } from '../../Global/Content/OffsetGrid'

const Lahinch = () => {
  const { image } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "lahinch-golf-club.jpeg" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 630, maxHeight: 420) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <GridItem
      title="Lahinch Golf Club"
      description="A world renowned links golf course often described as the “St. Andrew’s” of Ireland. Lahinch is actually two 18 hole courses, the Old Course, is between the road and the sea, and situated on the opposite side of the road is the Castle Course."
      sources={image.childImageSharp.fluid}
      imageAlt="Golf trips to Lahinch Golf Club with Chauffeur Me."
    />
  )
}

export default Lahinch